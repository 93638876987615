import React from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Table from 'react-bootstrap/Table'
import { Divider } from 'semantic-ui-react'
import './styles/MSstudents.css'
import nckuimg from './imgs/NCKU.png'
import Student from './student'
import hank from './imgs/hank.png'
import frank from './imgs/frank.png'
import kaichi from './imgs/kaichi.png'
import chao from './imgs/chao.png'
import cat from './imgs/cat.png'
import chloe from './imgs/chloe.png'
import happy from './imgs/happy.png'
import way from './imgs/way.png'
import tiff from './imgs/Tiff.png'
import jack from './imgs/Jack.png'
import early from './imgs/Early.png'
import felix from './imgs/Felix.png'

class MSstudents extends React.Component{
    constructor(props){
        super(props);

        let students = [ 
                {img : hank, name : "陳冠瑋", engName: ""},
                {img : frank, name : "徐崇翔", engName: ""},
                {img : chloe, name : "王芊涵", engName: ""},
                {img : kaichi , name : "黃愷齊", engName: ""},
                {img : cat, name : "莊人瑾", engName: ""},
                {img : chao, name : "趙璟琛", engName: ""},
                {img : happy, name : "吳迦樂", engName: ""},
                {img : way, name : "黃啟維", engName: ""},
                {img : jack, name : "林彥丞", engName: ""},
                {img : tiff, name : "楊婷芸", engName: ""},
                {img : early, name : "黃妍綺", engName: ""},
                {img : felix, name : "郭冠甫", engName: ""}
            ];

        
        this.state = {
            students : students
        }
    }

    render(){
        return (<>
            <Container id="MasterStudents" className="students">
                <Row>
                    <Col>
                        <Divider horizontal><h3>Master Students</h3></Divider>
                    </Col>
                </Row>
                <Row>
                    {this.state.students.map((value, index)=> (
            		<Col key={index}>
              			<Student img={value.img} name={value.name} engName={value.engName} />
            		</Col>
                    ))}
                </Row>
            </Container>
        </>);
    }
}

export default MSstudents;
